import 'animate.css/animate.min.css';
import { ContainerWrapper, Layout, SEOEN } from '../components/common';
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { IntroEN } from '../components/theme';
import { AboutUsEN } from '../components/theme/AboutUsEN';
import { AdvertisementsEN } from '../components/theme/AdvertisementsEN';
import { FooterEN } from '../components/theme/FooterEN';
import { MoviesEN } from '../components/theme/MoviesEN';
import { MusicVideosEN } from '../components/theme/MusicVideosEN';
import { PhotographyEN } from '../components/theme/Photographyen';

export default () => (
  <Layout>
    <SEOEN />
    <ParallaxProvider>
      <IntroEN />
    </ParallaxProvider>

    <ContainerWrapper>
      <AboutUsEN />
      <AdvertisementsEN />
      <MusicVideosEN />
      <MoviesEN />
      {/* <PhotographyEN /> */}
      {/* <Contact /> */}
      <FooterEN />
    </ContainerWrapper>
  </Layout>
);
