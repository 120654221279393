import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Wrapper } from './styles';

const NavbarLinksEN = ({ desktop, footer, toggle, sidebar }) => (
  <Wrapper desktop={desktop} footer={footer}>
    <AnchorLink onClick={() => sidebar && toggle && toggle(!sidebar)} href="#about-us">
      About us
    </AnchorLink>
    <AnchorLink onClick={() => sidebar && toggle && toggle(!sidebar)} href="#advertisements">
      Video advertisement
    </AnchorLink>
    <AnchorLink onClick={() => sidebar && toggle && toggle(!sidebar)} href="#music-videos">
      Music video
    </AnchorLink>
    <AnchorLink onClick={() => sidebar && toggle && toggle(!sidebar)} href="#movies">
      Movie
    </AnchorLink>
    <AnchorLink onClick={() => sidebar && toggle && toggle(!sidebar)} href="#photography">
      Photography
    </AnchorLink>
    {/* <AnchorLink onClick={() => sidebar && toggle && toggle(!sidebar)} href="#contact">
      Kontakt
    </AnchorLink> */}
  </Wrapper>
);

export default NavbarLinksEN;
