import { Col, Copyright, Description, Flex, FooterWrapper, Heading, Wrapper } from '../../../components/theme/Footer/styles';
import { Link } from 'gatsby';
import React from 'react';
import NavbarLinksEN from '../Header/NavbarLinksEN';

const icon2 = require('../../../../static/favicon/fav-white.png');

export const FooterEN = () => (
  <Wrapper>
    <Flex>
      <Col>
        <Heading>Map</Heading>

        <div>
          <NavbarLinksEN desktop footer />
        </div>
      </Col>

      <Col>

        <Heading>Contact</Heading>

        <Description>
          <h2>Maksymilian Grela</h2>
          <h2>Owner</h2>

          <br />

          <div>
            Email: <a href="mailto:kontakt@maxrec.pl">kontakt@maxrec.pl</a>
          </div>
          <div>
            Tel: <a href="tel:+48664205145">+48 664 205 145</a>
          </div>
        </Description>

      </Col>

      <Col>
        <Heading style={{visibility: 'hidden'}}>Test</Heading>

        <Description>
          <h2>Bernard Grela</h2>
          <h2>Key Account Manager</h2>

          <br />
          <div>
            Email: <a href="mailto:bernard@maxrec.pl">bernard@maxrec.pl</a>
          </div>
          <div>
            Tel: <a href="tel:+48608399295">+48 608 399 295</a>
          </div>

        </Description>
      </Col>

      <Col
        style={{ width: '100%', maxWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <img width="70" src={icon2} />
      </Col>
    </Flex>

    <FooterWrapper>
      <Copyright>
        <div>
          <span>© {new Date().getFullYear()} All rights reserved</span>{' '}
          <span>
            <Link to="/privacy">Privacy policy</Link>
          </span>{' '}
          <span>
            Made by <a href="mailto:contact@pietrzakadrian.com">Adrian Pietrzak</a>
          </span>
        </div>
      </Copyright>

      {/* <LinksWrapper>
        <Links>
          {social.map(({ id, name, link, icon }) => (
            <a key={id} href={link} target="_blank" rel="noopener noreferrer">
              <img width="35" src={icon} alt={name} />
            </a>
          ))}
        </Links>
      </LinksWrapper> */}
    </FooterWrapper>
  </Wrapper>
);
