import { ComponentWrapper, Container, TextWrapper } from '../../../components/common';
import { H3 } from '../../../components/common/Typography';
import React from 'react';

export const AboutUsEN = () => (
  <ComponentWrapper large as={Container} id="about-us">
    <H3>About us</H3>

    <TextWrapper left>
      <br />
      MAXREC Film Studio produces advertising films, fictional films, corporate & image videos and also advertising
      spots and music videos.
      <br />
      <br />
      We also carry out photo sessions.
      <br />
      <br />
      We provide our services mainly in Warsaw, but cooperation is possible throughout the country and abroad.
      <br />
      <br />
      <br />
      <p style={{ textAlign: 'center' }}>We invite you to cooperation.</p>
    </TextWrapper>
  </ComponentWrapper>
);
