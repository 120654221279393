import { ComponentWrapper, Container, GridTest, H3, VideoWrapper } from '../../../components/common';
import React from 'react';
import ReactPlayer from 'react-player';

export const MoviesEN = () => (
  <ComponentWrapper as={Container} id="movies">
    <H3>Movie</H3>

    {/* <VideoWrapper>
      <VideoGrid>
        {movies.map(video => (
          <YouTubeToggle key={video.id} video={video} />
        ))}
      </VideoGrid>
    </VideoWrapper>

    <ReactPlayer
      width="100%"
      height="100%"
      url="https://maxrec.pl/files/KITZINI%20BENTO%20BOX%20WITH%20TITLES%20V1.3.mp4"
      controls
    /> */}

    <VideoWrapper>
      <GridTest>
        <div>
          <ReactPlayer width="100%" height="210px" url="https://youtu.be/vi0AY3qHZV8" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://youtu.be/eQXJS9rdwmM" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="        https://youtu.be/M0Iw2q-AMz4" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://www.youtube.com/watch?v=4EB5qMcFj8w" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://www.youtube.com/watch?v=sY3K-8QHnhI" controls />
        </div>

        <div>
          <ReactPlayer width="100%" height="210px" url="https://www.youtube.com/watch?v=VAn4C2l-Jb4" controls />
        </div>

        {/* 
        <div className="is-last">
          <ReactPlayer width="100%" height="210px" url="https://www.youtube.com/watch?v=7zixHshR1s8" controls />
        </div> */}
      </GridTest>
    </VideoWrapper>
  </ComponentWrapper>
);
